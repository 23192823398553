.container {
    position: relative;
}

.page {
    position: absolute;
    left: 15px;
    right: 15px;
}

.page-enter {
    opacity: 0;
    transform: scale(1.2);
}

.page-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 300ms, transform 300ms;
}

.page-exit {
    opacity: 1;
    transform: scale(1);
}

.page-exit-active {
    opacity: 0;
    transform: scale(0.5);
    transition: opacity 300ms, transform 300ms;
}